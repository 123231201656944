import {
  ActionButton,
  Flex,
  Icon,
  TableWidget,
  TextButton,
  Token,
  Text,
} from '@revolut/ui-kit'
import { useGetGoal } from '@src/api/goals'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import React from 'react'

export const TargetsList = ({
  onSelected,
  viewMode,
}: {
  onSelected: (kpi: KpiInterface) => void
  viewMode: boolean
}) => {
  const { values, errors } = useLapeContext<GoalsInterface>()
  const updateType = values.kpis?.length ? values.kpis[0].update_type : undefined
  const hasErrors = !!errors.kpis

  // need to fetch goal because goals list endpoint doesn't return review cycles for targets
  const { data: parentGoal } = useGetGoal(values.parent?.id)

  const getKPIsProps = () => {
    switch (updateType) {
      case UpdateTypes.cascaded:
        return {
          data: parentGoal?.kpis || [],
          count: values.parent?.kpis.length || 0,
          noDataMessage: values.parent?.kpis
            ? undefined
            : 'Select parent goal to see cascaded targets',
          hiddenCells: { actions_column: true },
        }
      default:
        return {
          data: values.kpis,
          count: values.kpis.length,
        }
    }
  }

  const cells: ColumnCellInterface<KpiInterface>[] = viewMode
    ? [
        {
          ...kpiGenericNameColumn,
          title: 'Name',
          sortKey: null,
          filterKey: null,
          width: 300,
        },
        {
          ...kpiPerformanceColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          ...kpiInitialValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiCurrentValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiTargetColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiUnitColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          ...kpiUpdateTypeColumn,
          title: 'Type',
          sortKey: null,
          filterKey: null,
          width: 80,
        },
      ]
    : [
        {
          type: CellTypes.insert,
          idPoint: 'review_cycle.cycle_id',
          dataPoint: 'review_cycle',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          insert: ({ data }) => {
            const target =
              (data.targets && data.targets[0]) ||
              (data.target_epics && data.target_epics[0])

            if (target?.employee_cycle) {
              return target.employee_cycle.name
            }

            return target?.review_cycle?.name || ' - '
          },
          title: 'Cycle',
          width: 100,
        },
        {
          ...kpiUpdateTypeColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          ...kpiGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 300,
        },
        {
          ...kpiInitialValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiTargetColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiUnitColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }) => {
            return values.kpis[0].update_type === UpdateTypes.cascaded ? null : (
              <Flex gap="s-8" justifyContent="flex-end">
                <TextButton onClick={() => onSelected(data)}>
                  <Icon name="Pencil" size={16} color={Token.color.greyTone50} />
                </TextButton>
                <TextButton
                  onClick={() => {
                    values.kpis = []
                  }}
                >
                  <Icon name="Delete" size={16} color={Token.color.greyTone50} />
                </TextButton>
              </Flex>
            )
          },
          title: '',
          width: 80,
        },
      ]

  return values.kpis?.length ? (
    <TableWidget style={{ padding: 0 }}>
      {updateType === UpdateTypes.cascaded ? (
        <TableWidget.Actions>
          <ActionButton
            onClick={() => onSelected(values.kpis[0])}
            useIcon="ArrowExchange"
          >
            Change target type
          </ActionButton>
        </TableWidget.Actions>
      ) : null}
      {hasErrors ? (
        <TableWidget.Status>
          <Text variant="caption" color={Token.color.error}>
            Some of the targets are invalid
          </Text>
        </TableWidget.Status>
      ) : null}
      <TableWidget.Table>
        <AdjustableTable<KpiInterface & { actions_column?: never }>
          hideCountAndButtonSection={viewMode}
          name={TableNames.KPIs}
          dataType="Target"
          row={{
            highlight: () => (errors.kpis ? Token.color.inputError : ''),
            cells,
          }}
          {...getKPIsProps()}
        />
      </TableWidget.Table>
    </TableWidget>
  ) : null
}
