import { API, selectorKeys } from '../constants/api'
import { apiWithoutHandling, onboardingHandleError } from './index'
import { OptionInterface } from '../interfaces/selectors'
import { useFetch } from '@src/utils/reactQuery'
import { ApiVersion } from '@src/interfaces'

export const getSelectors = async (
  type: selectorKeys,
  apiVersion?: ApiVersion,
  apiHandler = apiWithoutHandling,
) =>
  apiHandler
    .get<{ options: OptionInterface[] }>(
      API.SELECTORS,
      {
        params: {
          name: type,
        },
      },
      apiVersion,
    )
    .catch(onboardingHandleError)

export const useGetSelectors = <T = OptionInterface>(
  type: selectorKeys | null,
  apiVersion?: ApiVersion,
) => {
  const context = useFetch<{ options: T[] }>(type ? API.SELECTORS : null, apiVersion, {
    params: {
      name: type,
    },
  })

  return { ...context, data: context.data?.options }
}
