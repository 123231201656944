import { AxiosPromise } from 'axios'

import { filterSortPageIntoQuery } from '@src/utils/table'
import { API } from '@src/constants/api'
import { EntityInterface, ContractingCountryInterface } from '@src/interfaces/enitities'
import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'

import { api, apiWithoutHandling } from './index'
import { useFetch } from '@src/utils/reactQuery'
import { Currency } from '@src/interfaces/selectors'

export const entitiesRequests: RequestInterface<EntityInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.ENTITIES, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.ENTITIES}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.ENTITIES}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ENTITIES}/${id}`),
  postItem: async data => api.post(API.ENTITIES, data),
}

export const entitiesRequestsNew: RequestInterfaceNew<EntityInterface> = {
  get: async ({ id }) => api.get(`${API.ENTITIES}/${id}`),
  submit: async data => apiWithoutHandling.post(API.ENTITIES, data),
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.ENTITIES}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.ENTITIES}/${id}`),
}

export const contractingCountriesRequests: RequestInterfaceNew<ContractingCountryInterface> =
  {
    get: async ({ id }) => api.get(`${API.CONTRACTING_COUNTRIES}/${id}`),
    submit: async data => apiWithoutHandling.post(API.CONTRACTING_COUNTRIES, data),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.CONTRACTING_COUNTRIES}/${id}`, data),
    delete: async ({ id }) => api.delete(`${API.CONTRACTING_COUNTRIES}/${id}`),
  }

export const getContractingCountries = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<ContractingCountryInterface>
> =>
  api.get(API.CONTRACTING_COUNTRIES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useLocationCurrency = (id?: number) =>
  useFetch<{ currency: Currency | null }>(
    id ? `${API.LOCATIONS}/${id}` : null,
    'v1',
    { params: { fields: 'currency' } },
    undefined,
    { refetchOnWindowFocus: false },
  )
