import React, { useEffect, useState } from 'react'
import {
  Box,
  chain,
  MoreBar,
  Popup,
  Text,
  Link as UIKitLink,
  copyToClipboard,
  Button,
} from '@revolut/ui-kit'
import { LockClosed, LogoutDoor, Copy, Retry, Check } from '@revolut/icons'
import { useParams, Switch, Route } from 'react-router-dom'
import { connect } from 'lape'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { getAccountStatusColor, getAccountStatusTitle } from '../common'
import { ROUTES } from '@src/constants/routes'
import { useTheme } from '@src/styles/theme'
import SettingsButtons, { EditButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import {
  accountSettings,
  getAccountActivationLink,
  getAccountSettings,
  retryTenenatSetup,
} from '@src/api/settings'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import Form from '@src/features/Form/Form'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useCloseAccount } from '@src/api/tenants'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Information } from './Information'
import { Features } from './Features'
import { Invoices } from './Invoices'
import { AccountDiscounts } from './Discounts'

const REFRESH_STATUS_INTERVAL = 5000

const DetailsPage = () => {
  const { initialValues, values, reset } = useLapeContext<AccountsSettingsInterface>()
  const theme = useTheme()
  const params = useParams()

  const [copyPopupOpen, setCopyPopupOpen] = useState(false)
  const [activationLink, setActivationLink] = useState<string>()
  const [activationLinkPending, setActivationLinkPending] = useState(false)
  const [retrySetupPending, setRetrySetupPending] = useState(false)

  const { mutateAsync: closeAccount, isLoading: isClosePending } = useCloseAccount(
    String(values.id),
  )
  const [closeDialogOpen, setCloseDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (
      values.state === 'setup_failed' ||
      values.state === 'active' ||
      values.state === 'closed'
    ) {
      return undefined
    }
    const refreshInterval = setInterval(async () => {
      const response = await getAccountSettings(values.id)

      if (response.data.state !== values.state) {
        if (response.data.state === 'active') {
          pushNotification({
            value: 'Account set to active',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'setup_failed') {
          pushNotification({
            value: 'Setup failed',
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }
        reset(response.data)
      }
    }, REFRESH_STATUS_INTERVAL)

    return () => clearInterval(refreshInterval)
  }, [values.state])

  const getLink = () => {
    setActivationLinkPending(true)
    setActivationLink(undefined)

    getAccountActivationLink(values.id)
      .then(response => {
        setActivationLink(response.data.activation_url)
        setCopyPopupOpen(true)
      })
      .finally(() => setActivationLinkPending(false))
  }

  const onCloseAccount = () => {
    closeAccount([undefined, undefined])
      .then(() => {
        values.state = 'closed'
      })
      .finally(() => {
        setCloseDialogOpen(false)
      })
  }

  const onCopy = () => {
    if (activationLink) {
      copyToClipboard(activationLink).then(() => {
        pushNotification({
          value: 'Link copied to clipboard',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
    }
  }

  const retrySetup = () => {
    setRetrySetupPending(true)

    retryTenenatSetup(values.id)
      .then(() => {
        pushNotification({
          value:
            values.state === 'setup_failed'
              ? 'Retrying tenant setup'
              : 'Starting tenant setup',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
      .finally(() => setRetrySetupPending(false))
  }

  return (
    <>
      <PageHeader
        title={chain(
          initialValues.company_name,
          <Text color={getAccountStatusColor(values.state!, theme)}>
            {getAccountStatusTitle(values.state!)}
          </Text>,
        )}
        subtitle="Company account"
        backUrl={ROUTES.SETTINGS.ACCOUNTS.LIST}
      />

      <SettingsButtons mb="s-16">
        {values.state === 'setup_failed' || values.state === 'waiting_list' ? (
          <MoreBar.Action
            onClick={retrySetup}
            pending={retrySetupPending}
            useIcon={values.state === 'setup_failed' ? Retry : Check}
          >
            {values.state === 'setup_failed' ? 'Retry setup' : 'Approve'}
          </MoreBar.Action>
        ) : null}
        <EditButton route={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.GENERAL, params)} />
        <MoreBar.Action
          useIcon={Copy}
          onClick={getLink}
          pending={activationLinkPending}
          disabled={initialValues.state !== 'active'}
        >
          Copy activation URL
        </MoreBar.Action>
        {/* TODO: implement when BE is ready */}
        <MoreBar.Action variant="negative" disabled useIcon={LockClosed}>
          Suspend
        </MoreBar.Action>
        <MoreBar.Action
          variant="negative"
          disabled={values.state !== 'active'}
          useIcon={LogoutDoor}
          onClick={() => setCloseDialogOpen(true)}
        >
          Close account
        </MoreBar.Action>
      </SettingsButtons>

      <TabBarNavigation
        tabs={[
          {
            title: 'Information',
            path: ROUTES.SETTINGS.ACCOUNTS.INFORMATION,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, params),
          },
          {
            title: 'Account features',
            path: ROUTES.SETTINGS.ACCOUNTS.FEATURES,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.FEATURES, params),
          },
          {
            title: 'Invoices',
            path: ROUTES.SETTINGS.ACCOUNTS.INVOICES,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICES, params),
          },
          {
            title: 'Discounts',
            path: ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS, params),
          },
        ]}
      />

      <Switch>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.INFORMATION}>
          <Information />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.FEATURES}>
          <Features />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.INVOICES}>
          <Invoices />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS}>
          <AccountDiscounts />
        </Route>
      </Switch>

      <Popup
        open={copyPopupOpen}
        variant="bottom-sheet"
        onClose={() => setCopyPopupOpen(false)}
      >
        <Box style={{ overflowWrap: 'break-word' }}>
          <UIKitLink href={activationLink} target="_blank">
            {activationLink}
          </UIKitLink>
        </Box>
        <Popup.Actions horizontal>
          <Button onClick={() => setCopyPopupOpen(false)} variant="secondary">
            Close
          </Button>
          <Button onClick={onCopy} useIcon={Copy}>
            Copy link
          </Button>
        </Popup.Actions>
      </Popup>

      <ConfirmationDialog
        open={closeDialogOpen}
        onClose={() => setCloseDialogOpen(false)}
        onConfirm={onCloseAccount}
        loading={isClosePending}
        onReject={() => setCloseDialogOpen(false)}
        label="Close account"
        yesMessage="Close account"
        noMessage="Go back"
      />
    </>
  )
}

export const Details = connect(() => (
  <Form api={accountSettings} disableLocalStorageCaching>
    <DetailsPage />
  </Form>
))
