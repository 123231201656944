import {
  Item,
  VStack,
  Text,
  Token,
  Flex,
  ItemSkeleton,
  Side,
  ButtonSkeleton,
} from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  EpicOption,
  KpiGoals,
  KpiInterface,
  KpiReviewCycle,
  KpiTargets,
  UpdateTypes,
} from '@src/interfaces/kpis'
import React, { useEffect, useState } from 'react'
import { NewKPITargetFields } from '../../../KpiForm/common/NewKPITargetFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { TargetCycleSelector } from '../../../KpiForm/common/TargetCycleSelector'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ParentGoalField } from '../../common/ParentGoalField'
import { GoalsInterface } from '@src/interfaces/goals'
import { kpisRequestsNew, validateKpi } from '@src/api/kpis'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const TargetFieldsByType = ({
  type,
  cycle,
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
}) => {
  const { values } = useLapeContext<KpiInterface>()

  switch (type) {
    case UpdateTypes.roadmap:
      return cycle ? (
        <JiraSearchWidget
          onSelectionChange={selected => {
            if (!values.target_epics) {
              values.target_epics = []
            }

            const target = values.target_epics[0] || {}
            target.epics = Object.values(selected).map(
              epic =>
                ({
                  key: epic.key,
                  name: epic.epic_name,
                  owner: epic.owner,
                  url: epic.epic_url,
                  review_cycle: cycle as KpiReviewCycle,
                } as EpicOption),
            )
            values.target_epics[0] = target
          }}
        />
      ) : null

    case UpdateTypes.sql:
    case UpdateTypes.manual:
      return <NewKPITargetFields />
    default:
      return null
  }
}

const AddGoalTargetForm = ({
  onSave,
  initialCycle,
  parent,
  onParentChanged,
  contentType,
  onPatched,
}: {
  onSave?: (values: KpiInterface) => Promise<void>
  initialCycle?: ReviewCyclesInterface
  parent: GoalsInterface | null
  onParentChanged: (parent: GoalsInterface | null) => void
  contentType?: 'employees' | 'teams' | 'department'
  onPatched?: (kpi: KpiInterface) => void
}) => {
  const form = useLapeContext<KpiInterface & { parent: GoalsInterface | null }>()
  const { values } = form
  const [cycle, setCycle] = useState<ReviewCyclesInterface | undefined>(initialCycle)
  const updateTypesDictionary = {
    [UpdateTypes.cascaded]: {
      name: 'Cascade from a higher level KPI',
      description: 'The performance will be the same as the parent KPI',
    },
    [UpdateTypes.sql]: {
      name: 'Auto-update KPI',
      description: 'Progress determined daily via a defined data connection',
    },
    [UpdateTypes.roadmap]: {
      name: 'Roadmap KPI',
      description:
        'Progress will be determined by the completion of linked roadmap deliverables',
    },
    [UpdateTypes.manual]: {
      name: 'Manual KPI',
      description: 'The performance will be measured by manual inputs to the KPI',
    },
  }

  const updateTypeOption = values.update_type
    ? { id: values.update_type, ...updateTypesDictionary[values.update_type] }
    : undefined

  const hasTarget =
    values.update_type === UpdateTypes.sql || values.update_type === UpdateTypes.manual

  const hasDeliverables = values.update_type === UpdateTypes.roadmap

  const target =
    (hasDeliverables
      ? values.target_epics && values.target_epics[0]
      : values.targets && values.targets[0]) || undefined

  useEffect(() => {
    values.is_inherited = values.update_type === UpdateTypes.cascaded

    if (hasTarget && !values.targets?.length) {
      values.targets = [{ is_top_down: false } as KpiTargets]
    }
  }, [values.update_type])

  useEffect(() => {
    const opposites = {
      review_cycle: 'employee_cycle',
      employee_cycle: 'review_cycle',
    }
    if (cycle && target) {
      const cycleKey = [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(
        cycle?.category,
      )
        ? 'employee_cycle'
        : 'review_cycle'
      const fieldToClear = opposites[cycleKey] as 'employee_cycle' | 'review_cycle'

      if (target[cycleKey]?.id !== cycle.id) {
        target[cycleKey] = cycle
        target[fieldToClear] = undefined
      }
    }
  }, [cycle, target])

  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100%">
      <VStack space="s-16">
        <Item>
          <Item.Content>
            <Item.Title>Set target for your goal</Item.Title>
            <Item.Description>
              Select the performance cycle and the target type for tracking the progress
              towards your goal.
            </Item.Description>
          </Item.Content>
        </Item>
        <LapeRadioSelectInput<IdAndName<UpdateTypes> & { description: string }>
          label="Target type"
          value={updateTypeOption}
          onChange={option => {
            values.update_type = option?.id
          }}
          name="update_type"
          options={Object.entries(updateTypesDictionary)
            .filter(([type]) =>
              // company goals should not have cascaded targets
              values.is_company ? type !== UpdateTypes.cascaded : true,
            )
            .map(([id, value]) => ({
              value: { ...value, id: id as UpdateTypes },
              label: value.name,
            }))}
          searchable={false}
        >
          {option => (
            <VStack m="-s-2">
              <Text variant="primary">{option.label}</Text>
              <Text variant="caption" color={Token.color.greyTone50}>
                {option.value.description}
              </Text>
            </VStack>
          )}
        </LapeRadioSelectInput>

        {values.update_type && values.update_type === UpdateTypes.cascaded ? (
          <ParentGoalField
            contentType={contentType}
            required
            value={parent}
            onChange={selected => {
              onParentChanged(selected)
            }}
          />
        ) : null}

        {values.update_type && values.update_type !== UpdateTypes.cascaded && (
          <>
            <TargetCycleSelector
              onSelect={setCycle}
              ownerId={values.owner?.id}
              isEmployee={values.is_employee}
              initialCycle={
                (target?.review_cycle ||
                  target?.employee_cycle ||
                  initialCycle) as ReviewCyclesInterface
              }
            />
            <LapeNewInput name="name" label="Target name" required />
          </>
        )}
        <TargetFieldsByType type={values.update_type} cycle={cycle} />
      </VStack>
      <Side.Actions alignSelf="center">
        <NewSaveButtonWithPopup<KpiInterface>
          width="350px"
          onClick={onSave ? () => onSave(form.values) : undefined}
          useValidator
          onAfterSubmit={result => onPatched && onPatched(result)}
        />
      </Side.Actions>
    </Flex>
  )
}

type AddGoalTargetFormProps = {
  onSave: (kpi: KpiInterface) => Promise<void>
  initialValues: Partial<KpiInterface>
  initialCycle?: ReviewCyclesInterface
  parent: GoalsInterface | null
  onParentChanged: (parent: GoalsInterface | null) => void
  contentType?: 'teams' | 'employees' | 'department'
  onPatched: (kpi: KpiInterface) => void
}

export default ({
  onSave,
  initialValues,
  initialCycle,
  parent,
  onParentChanged,
  contentType,
  onPatched,
}: AddGoalTargetFormProps) => {
  return initialValues.id ? (
    <Form<KpiInterface>
      api={kpisRequestsNew}
      forceParams={{ id: String(initialValues.id) }}
      disableLocalStorageCaching
      ignoreLocationState
      loadingState={
        <>
          <VStack space="s-16">
            <ItemSkeleton>
              <ItemSkeleton.Content>
                <ItemSkeleton.Description />
                <ItemSkeleton.Title />
                <ItemSkeleton.Description />
              </ItemSkeleton.Content>
            </ItemSkeleton>
            <InputsSkeleton />
            <InputsSkeleton />
          </VStack>
          <Side.Actions alignSelf="center">
            <ButtonSkeleton width="350px" />
          </Side.Actions>
        </>
      }
    >
      <AddGoalTargetForm
        contentType={contentType}
        initialCycle={initialCycle}
        parent={parent}
        onParentChanged={onParentChanged}
        onPatched={onPatched}
      />
    </Form>
  ) : (
    <Form<KpiInterface>
      disableLocalStorageCaching
      ignoreLocationState
      initialValues={{
        unit: '%',
        targets: [{ kpi_goal: KpiGoals.increase } as KpiTargets],
        ...initialValues,
      }}
    >
      <AddGoalTargetForm
        contentType={contentType}
        onSave={async data => {
          const cascadedFields = {
            update_type: undefined,
            parent: { id: parent?.kpis[0]?.id } as KpiInterface,
          }
          const validationData = {
            ...data,
            ...(data.update_type === UpdateTypes.cascaded ? cascadedFields : {}),
          }
          await validateKpi(validationData)
          onSave(data)
        }}
        initialCycle={initialCycle}
        parent={parent}
        onParentChanged={onParentChanged}
      />
    </Form>
  )
}
