export type Entity =
  | 'EmployeeContract'
  | 'Employees'
  | 'ReviewsCycleEligibleEmployee'
  | 'InterviewRound'
  | 'Candidate'
  | 'RecruitmentGroups'
  | 'Requisitions'
  | 'Roles'
  | 'SpecialisationPerformanceWeight'
  | 'KPI'
  | 'LeadershipPosition'
  | 'Teams'
  | 'ReferralBonus'
  | 'OfferForm'
  | 'RequisitionHireEvent'
  | 'CandidateFreezingPeriod'
  | 'ApplicationForm'
  | 'EmployeeRequisitionHire'
  | 'Skillset'

export type DependencyError = {
  model_id: number
  model: Entity
  label?: string
}

export type EntityProps = { items: DependencyError[]; entityId?: number; prefix?: string }

export type EntityDetails = {
  listUrl?: string
  title: string
  itemUrl?: (id: number) => string
  handleDelete?: (id: number) => Promise<void>
  items: DependencyError[]
}

type EntityDetailsMapper = (props: EntityProps) => EntityDetails

const joinModelIds = (dependencies: DependencyError[]): string =>
  dependencies.map(dep => dep.model_id).join(',')

const skillsetDetails: EntityDetailsMapper = ({ items }) => ({
  title: 'Skillsets',
  items,
})

const employeeRequisitionHireDetails: EntityDetailsMapper = ({ items }) => ({
  items,
  title: 'Employee requisition hire',
})

const applicationFormDetails: EntityDetailsMapper = ({ items }) => ({
  items,
  title: 'Application forms',
})

const candidateFreezingPeriodDetails: EntityDetailsMapper = ({ items }) => ({
  items,
  title: 'Candidate freezing periods',
})

const requisitionHireEventDetails: EntityDetailsMapper = ({ items }) => ({
  items,
  title: 'Requisition hire events',
})

const offerDetails: EntityDetailsMapper = ({ items }: EntityProps) => ({
  items,
  title: 'Offer forms',
})

const employeeDetails: EntityDetailsMapper = ({ items }) => ({
  items,
  listUrl: `/people/employees?id=${joinModelIds(items)}&employee_type=&status=`,
  title: 'Employees',
  itemUrl: (id: number) => `/employees/preview/${id}`,
})

const employeeContractDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/people/contracts?id=${joinModelIds(items)}`,
  title: 'Employee Contracts',
  itemUrl: (id: number) => `/employees/contract/${id}`,
})

const reviewsCycleEligibleEmployeeDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/performance/performance-reviews/eligible-employees?id=${joinModelIds(
    items,
  )}&cycle__offset=`,
  title: 'Eligible Employees',
  itemUrl: id => `/employees/performance-summary/${id}`,
})

const interviewRoundDetails = ({
  items,
  prefix,
  entityId,
}: EntityProps): EntityDetails => ({
  items,
  listUrl: `/${prefix}/hiring-process/${entityId}`,
  title: 'Interview rounds',
})

const candidateDetails = ({ items, prefix, entityId }: EntityProps): EntityDetails => ({
  items,
  listUrl: ['role', 'specialisation'].includes(prefix!)
    ? `/${prefix}/candidates/${entityId}?id=${joinModelIds(items)}`
    : undefined,
  title: 'Candidates',
})

const recrutmentGroupsDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/apps/hiring-process/recruitment-groups?id=${joinModelIds(items)}`,
  title: 'Recrutment groups',
  itemUrl: id => `/recruitment-groups/general/${id}`,
})

const requsitionDetails = ({ items, prefix, entityId }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/${prefix}/requisitions/${entityId}?id=${joinModelIds(items)}&status=`,
  title: 'Requsitions',
  itemUrl: id => `/requisition/role/${id}`,
})

const rolesDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/functions/roles?id=${joinModelIds(items)}`,
  title: 'Roles',
  itemUrl: id => `/role/preview/${id}`,
})

const specialisationDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/functions/specialisations?id=${joinModelIds(items)}`,
  title: 'Specialisations',
})

const kpiDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/performance/kpis?id=${joinModelIds(items)}&review_cycle__offset=`,
  title: 'KPIs',
  itemUrl: id => `/KPI/general/${id}`,
})

const leadershipPositionsDetails = ({ items, entityId }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/department/leadership/${entityId}?id=${joinModelIds(items)}`,
  title: 'Leadership positions',
})

const teamsDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/organisation/teams?id=${joinModelIds(items)}`,
  title: 'Teams',
  itemUrl: id => `/team/summary/${id}`,
})

const referralBonusDetails = ({ items }: EntityProps): EntityDetails => ({
  items,
  listUrl: `/hub/apps/candidates/referral-bonus?id=${joinModelIds(items)}`,
  title: 'Referral bonuses',
  itemUrl: id => `/referral-bonuses/general/${id}`,
})

export const getModelDetails = (model: Entity, props: EntityProps) => {
  switch (model) {
    case 'EmployeeContract':
      return employeeContractDetails(props)
    case 'Employees':
      return employeeDetails(props)
    case 'ReviewsCycleEligibleEmployee':
      return reviewsCycleEligibleEmployeeDetails(props)
    case 'InterviewRound':
      return interviewRoundDetails(props)
    case 'Candidate':
      return candidateDetails(props)
    case 'RecruitmentGroups':
      return recrutmentGroupsDetails(props)
    case 'Requisitions':
      return requsitionDetails(props)
    case 'Roles':
      return rolesDetails(props)
    case 'SpecialisationPerformanceWeight':
      return specialisationDetails(props)
    case 'KPI':
      return kpiDetails(props)
    case 'LeadershipPosition':
      return leadershipPositionsDetails(props)
    case 'Teams':
      return teamsDetails(props)
    case 'ReferralBonus':
      return referralBonusDetails(props)
    case 'OfferForm':
      return offerDetails(props)
    case 'RequisitionHireEvent':
      return requisitionHireEventDetails(props)
    case 'CandidateFreezingPeriod':
      return candidateFreezingPeriodDetails(props)
    case 'ApplicationForm':
      return applicationFormDetails(props)
    case 'EmployeeRequisitionHire':
      return employeeRequisitionHireDetails(props)
    case 'Skillset':
      return skillsetDetails(props)
    default:
      return {
        items: props.items,
        title: model,
      }
  }
}
