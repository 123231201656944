import { ButtonSkeleton, MoreBar } from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import {
  canAddDepartmentKpi,
  canAddFunctionKpi,
  canAddRoleKpi,
  canAddTeamKpi,
} from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useDefaultWriteAccess = () => {
  const { entity } = useOrgEntity()

  const canAddGoal = entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanCreateGoal,
  )

  const canAddGloabalDepartmentKPI = useSelector(canAddDepartmentKpi)
  const canAddGloabalTeamKPI = useSelector(canAddTeamKpi)
  const canAddGlobalFunctionKPI = useSelector(canAddFunctionKpi)
  const canAddGlobalRoleKpi = useSelector(canAddRoleKpi)

  if (canAddGoal) {
    return true
  }

  // below is legacy and workaround to ship FE code before BE implements a proper permissions
  switch (entity?.type) {
    case EntityTypes.company:
      return canAddGloabalDepartmentKPI
    case EntityTypes.department:
      return entity?.data.field_options.permissions?.includes(
        PermissionTypes.CanAddKPIsDepartment,
      )
    case EntityTypes.employee:
    case EntityTypes.employees:
      return canAddGloabalTeamKPI
    case EntityTypes.function:
      return canAddGlobalFunctionKPI
    case EntityTypes.role:
      return canAddGlobalRoleKpi
    case EntityTypes.team:
    case EntityTypes.teams:
      return entity?.data.field_options.permissions?.includes(
        PermissionTypes.CanAddKPIsTeam,
      )
    default:
      return false
  }
}

export const AddGoalAction = () => {
  const location = useLocation()
  const { navigateWithEntity, entity } = useOrgEntity()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const canAdd = useDefaultWriteAccess()

  if (isLoading) {
    return <ButtonSkeleton />
  }

  const handleNew = () => {
    navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.NEW), {
      reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
      initialValues: {
        object_id: entity ? entity.data.id : undefined,
        is_company: entity?.type === EntityTypes.company,
        content_type: contentTypes?.find(({ model }) => model === entity?.type),
      },
    })
  }

  return canAdd ? (
    <MoreBar.Action onClick={handleNew} useIcon="Plus" pending={!contentTypes}>
      Add new goal
    </MoreBar.Action>
  ) : null
}
