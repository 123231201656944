import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'

const getTargetBoundaries = (
  kpi: Partial<KpiInterface>,
): Partial<Pick<KpiInterface, 'initial_value' | 'target' | 'unit'>> => {
  const defaultBoundaries = {
    initial_value: 0,
    target: 100,
    unit: '%',
  }

  const target = kpi.targets && kpi.targets[0]

  if (target) {
    return {
      initial_value: target.initial_value === null ? undefined : target.initial_value,
      target: target.target === null ? undefined : target.target,
      unit: kpi.unit,
    }
  }

  return defaultBoundaries
}

const getNameIfEpic = (kpi: Partial<KpiInterface>): string | undefined => {
  return (
    kpi.target_epics &&
    kpi.target_epics[0] &&
    kpi.target_epics[0].epics &&
    kpi.target_epics[0].epics[0]?.name
  )
}

export const mapKpi = (
  kpi: Partial<KpiInterface>,
  goal: GoalsInterface,
): Partial<KpiInterface> => {
  return {
    ...kpi,
    name: kpi.name || getNameIfEpic(kpi) || goal.name,
    ...getTargetBoundaries(kpi),
  }
}
