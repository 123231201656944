import { goalsListTableRequests } from '@src/api/goals'
import { useGoalParentFilter } from '@src/features/Goals/useGoalFilters'
import { GoalsInterface } from '@src/interfaces/goals'
import React from 'react'
import { OptionInterface } from '../../QueryForm/HistoryTable'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'

export const ParentGoalField = ({
  contentType,
  ...rest
}: {
  contentType?: 'employees' | 'teams' | 'department'
} & RadioSelectInputProps<GoalsInterface>) => {
  const parentFilter = useGoalParentFilter(contentType)

  return (
    <RadioSelectInput<GoalsInterface>
      selector={
        parentFilter
          ? () =>
              goalsListTableRequests
                .getItems({
                  filters: [parentFilter],
                })
                .then(response => response.data.results as unknown as OptionInterface[])
          : undefined
      }
      loading={!parentFilter}
      label="Parent goal"
      {...rest}
    />
  )
}
