import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { GoalContentType } from '@src/interfaces/goals'

export const useGetGoalContentTypeFilter = (
  entityType?: EntityTypes,
): FilterByInterface | null => {
  const { data } = useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)

  const getTypeFilter = (type: GoalContentType['model']): FilterByInterface | null => {
    if (!data || !entityType) {
      return null
    }
    const typeObject = data.find(({ model }) => model === type)

    return typeObject
      ? {
          columnName: 'content_type_id',
          filters: [{ id: typeObject.id, name: typeObject.model }],
          nonResettable: true,
        }
      : null
  }

  switch (entityType) {
    case EntityTypes.company:
      return {
        columnName: 'is_company',
        filters: [{ name: 'True', id: 'True' }],
      }

    case EntityTypes.team:
    case EntityTypes.teams:
      return getTypeFilter('teams')

    case EntityTypes.department:
      return getTypeFilter('department')

    case EntityTypes.employee:
    case EntityTypes.employees:
      return getTypeFilter('employees')

    default:
      return null
  }
}

export const useGoalParentFilter = (contentType?: GoalContentType['model']) => {
  const mapContentTypeToParentEntity: Record<GoalContentType['model'], EntityTypes> = {
    department: EntityTypes.company,
    teams: EntityTypes.department,
    employees: EntityTypes.team,
  }

  return useGetGoalContentTypeFilter(
    contentType ? mapContentTypeToParentEntity[contentType] : undefined,
  )
}
