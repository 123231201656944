import { api, apiWithoutHandling } from '@src/api'
import {
  GetRequestData,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'

export const goalsRequests: RequestInterfaceNew<GoalsInterface> = {
  get: async ({ id }) => {
    return api.get(`${API.GOALS}/${id}`)
  },
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.GOALS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.GOALS, data),
}

export const goalsListTableRequests: TableRequestInterface<
  GoalsInterface & { children?: GoalsInterface['kpis'] },
  { overall_progress: number }
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api
      .get<GetRequestData<GoalsInterface>>(
        `${API.GOALS}`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v1',
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(row => ({ ...row, children: row.kpis })),
        },
      })),
  getStats: async ({ filters, page }) =>
    apiWithoutHandling.get(
      `${API.GOALS}/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v1',
    ),
}

export const useGetGoal = (id?: number) => {
  return useFetch<GoalsInterface>(id ? `${API.GOALS}/${id}` : null)
}

// todo: proper implementation
export const fetchGoalGraph = async () =>
  ({
    statues: 200,
    data: { targets: [], progress_history: [] },
  } as unknown as AxiosPromise<PerformanceChartCycles>)

export const approveGoal = (id: number) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/approve`)
}

export const blukApproveGoals = (goals: Array<{ id: GoalsInterface['id'] }>) => {
  return apiWithoutHandling.post<never>(`${API.GOALS}/bulkApprove`, { goals })
}

export const requestChange = (id: number, reason: string) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/reject`, {
    rejection_comment: reason,
  })
}

export const useGetGoalApprovalFlow = (id: number | undefined) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.GOALS}/${id}/approvals` : null)
}
